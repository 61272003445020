<script setup lang="ts">
interface Props {
img?: string;
size?: string;
alt?: string;
}

withDefaults(defineProps<Props>(), {
  alt: 'image',
  size: 'h-12 w-12'
});

const imgLoaded = ref(false);
</script>

<template>
  <div class="rounded-full flex-shrink-0" :class="size">
    <div v-if="img" class="flex rounded-full">
      <img
        v-show="imgLoaded"
        :class="size"
        class="rounded-full object-cover"
        referrerpolicy="no-referrer"
        :src="img"
        @load="imgLoaded = true"
        :alt="alt"
      />
      <div v-show="!imgLoaded" :class="size" class="rounded-full animate-pulse bg-gray-100"></div>
    </div>

    <div v-else :class="size" class="rounded-full overflow-hidden bg-gray-100 object-cover">
      <svg class="h-full w-full text-light-gray" fill="currentColor" viewBox="0 0 24 24">
        <path
          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
    </div>
  </div>
</template>

